import React, { Component, Fragment } from 'react'

import Home from './Home';
import Overview from './Overview/Overview';
import Feature from './Feature/Feature';
import Prices from './Prices/Prices';
import LandingImage from './LandingImage/LandingImage';
import Toolbar from './Toolbar/Toolbar';
import SideDrawer from '../Navigation/SideDrawer/SideDrawer';
import Footer from './Footer/Footer';

import feature1 from '../../img/feature1.png';
import feature2 from '../../img/feature2.png';

import appSuite from '../../img/app-suite.png';
import { properties } from '../../constants/properties';
import styles from './LandingPage.module.css';
import OverviewGrid from './Overview/OverviewGrid';


class LandingPage extends Component {

    state = {
        showSideDrawer: false
    }

    sideDrawerClosedHandler = () => {
        this.setState({ showSideDrawer: false })
    }

    sideDrawerToggleHandler = () => {
        this.setState((prevState) => {
            return { showSideDrawer: !prevState.showSideDrawer };
        });
    }

    render() {
        return (
            <Fragment>
                <div style={styles.Wrapper}>
                    <SideDrawer open={this.state.showSideDrawer} closed={this.sideDrawerClosedHandler} />
                    <Toolbar drawerToggleClicked={this.sideDrawerToggleHandler} />
                    <div className={styles.Landing}>
                        <Home />
                        <Overview
                            headline={properties.headlines.tagline}
                            description={properties.descriptions.taglineCopy} />
                        <OverviewGrid />
                        <div className={styles.DesktopOnly}>
                            <Feature
                                headline={properties.headlines.featureHeadline1}
                                subheadline={properties.subHeadlines.featureSubheadline1}
                                description={properties.descriptions.featureDescription1}
                                img={feature1} />

                            <Overview
                                headline={properties.headlines.tagline2}
                                description={properties.descriptions.taglineCopy2} />
                            <LandingImage img={appSuite} />
                            <Feature
                                headline={properties.headlines.featureHeadline2}
                                subheadline={properties.subHeadlines.featureSubheadline2}
                                description={properties.descriptions.featureDescription2}
                                img={feature2} />
                            <Overview
                                showLink
                                headline={properties.headlines.pricePlan}
                                description={properties.descriptions.pricePlan} />
                            <Prices />
                            <Footer />
                        </div>
                        <div className={styles.MobileOnly}>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default LandingPage;