import React, { Fragment } from 'react';

import Navigation from '../Navigation'

import Logo from '../../Logos/Logo/Logo'
import Backdrop from '../../UI/Backdrop/Backdrop';

import styles from './SideDrawer.module.css'

const SideDrawer = (props) => {
    let attachedClasses = [styles.SideDrawer, styles.Close]
    if (props.open) {
        attachedClasses = [styles.SideDrawer, styles.Open];
    }
    return (
        <Fragment>
            <Backdrop show={props.open} clicked={props.closed} />
            <div className={attachedClasses.join(' ')}>
                <div>
                    <Logo />
                </div>
                <nav>
                    <Navigation />
                </nav>

            </div>
        </Fragment>
    );
}

export default SideDrawer