import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './Footer.module.css';
import { SocialIcon } from 'react-social-icons';

const footer = () => (
    <div className={styles.Footer}>
        <br /><br /><br />
        <div className={styles.Wrapper}>
            <div className="container">
                <div className="row">
                    <div className="col-8">
                        <span className={styles.FooterTitle}> About Us</span>
                        <br /><br />
                        <p>YuleLog has been helping collectors keep track of their Christmas ornaments and other items since 1995.</p>
                        <p>Copyright © YuleLog Software {(new Date().getFullYear())} All rights reserved</p>
                    </div>
                    <div className="col-4">
                        <span className={styles.FooterTitle}>Follow Us</span>
                        <br /><br />
                        <SocialIcon fgColor="#fff" url="https://www.facebook.com/YuleLogSoftware" />&nbsp;&nbsp;&nbsp;
                        <SocialIcon fgColor="#fff" url="https://www.youtube.com/channel/UCAzKeCW6y6uIx-f0Io5RqsA" />&nbsp;&nbsp;&nbsp;
                        <SocialIcon fgColor="#fff" url="https://www.tiktok.com/@yulelogsoftware" />&nbsp;&nbsp;&nbsp;
                        <SocialIcon fgColor="#fff" url="https://www.instagram.com/yulelogsoftware/" />
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default footer;