const series = ["'Tis the Seasoning",
"12 Little Days of Christmas",
"A Celebration of Angels",
"A Charlie Brown Xmas *",
"A Christmas Story *",
"A Creature Was Stirring",
"A Creature Was Stirring *",
"A Pony for Christmas",
"A Pony for Christmas *",
"A Snoopy Christmas *",
"A Visit From Santa",
"A Visit From Santa *",
"A World Within",
"A Year of Disney Magic",
"A Year of Disney Magic *",
"Adoption *",
"African American Nativity *",
"African-American Holiday Barbie",
"Afternoon Tea",
"Alice in Wonderland",
"All About Mickey!",
"All American Trucks",
"All American Trucks *",
"All God's Children",
"All-American Trucks",
"American Girls Collection *",
"Angel Bells *",
"Angels Around the World",
"Animaniacs *",
"Anne of Green Gables",
"Antique Tractors",
"Apple Blossom Lane",
"Art Masterpiece",
"Arthur *",
"At the Ballpark",
"At the Ballpark *",
"Avatar *",
"Baby Fairy Messengers",
"Back to the Future *",
"Barbie",
"Barbie *",
"Barbie Angels *",
"Barbie Cases *",
"Barbie Doll",
"Barbie Fashion Minis *",
"Barbie Stocking Hangers *",
"Barbie as *",
"Baseball Heroes",
"Baseball Heroes *",
"Baseball Jerseys *",
"Battlestar Galactica *",
"Bearingers *",
"Beatrix Potter",
"Beautiful Birdhouse",
"Beauty of Birds",
"Beauty of Birds *",
"Betsey Clark",
"Betsey Clark : Home for Christmas",
"Betsey's Country Christmas",
"Better Together *",
"Between Us *",
"Bill's Boards",
"Birthday Wishes Barbie",
"Blessed Nativity Collection *",
"Blue Plate *",
"Blue's Clues *",
"Bob the Builder *",
"Brilliant Butterflies",
"Brilliant Butterflies *",
"Caddyshack *",
"Canadian Icons",
"Canadian Postal Exclusive  *",
"Canadian Sports Heroes",
"Candlelight Services",
"Caroling Cottages *",
"Carousel Ride",
"Carrousel",
"Cat Naps",
"Celebration Barbie",
"Centuries of Santa",
"Charming Hearts",
"Child's Age Collection *",
"Children's Collector Series",
"Chris Mouse",
"Christmas Angels",
"Christmas Bells",
"Christmas Carnival",
"Christmas Carousel",
"Christmas Carousel Horse Collection *",
"Christmas Classics",
"Christmas Commemorative",
"Christmas Commemorative *",
"Christmas Cupcakes",
"Christmas Cupcakes *",
"Christmas Is *",
"Christmas Is...",
"Christmas Kitty",
"Christmas Sky Line",
"Christmas Visitors",
"Christmas Window",
"Christmas Window *",
"Chuggington *",
"Cinderella *",
"Classic American Cars",
"Classic American Cars *",
"Claus &amp; Co R.R. *",
"Clothespin Soldier",
"Coca-Cola *",
"Collector's Plate",
"Collector's Plate - Easter",
"College *",
"Comic Book Heroes",
"Cookie Cutter Christmas",
"Cookie Cutter Christmas *",
"Cookie Cutter Through the Year",
"Cooking for Christmas *",
"Cool Decade",
"Cool Icicles",
"Cottontail Express",
"Country Christmas Collection *",
"Country Treasures Collection *",
"Cozy Critters",
"Cozy Critters *",
"Cozy Lil' Critters",
"Crayola *",
"Crayola Crayon",
"Cup of Cozy",
"Curious George *",
"Customly Yours *",
"Cute as a Button",
"Cute as a Button *",
"Daniel Tiger's Neighborhood *",
"Decking the Tree",
"Dickens Caroler Bell",
"Disney *",
"Disney Christmas Carolers *",
"Disney Egg Collection *",
"Disney Princess Celebration",
"Disney Princess Celebration *",
"Disney Snowflake Miniatures *",
"Disney/Pixar Legends",
"Doctor Who *",
"Dolls of the World",
"Donna Lee Angels *",
"Doorways Around the World",
"Down to Earth Angels *",
"Dr. Seuss *",
"Dr. Seuss Books",
"Dr. Seuss Books *",
"Dr. Who *",
"Dragon Ball *",
"Dream Horse *",
"Dreamworks *",
"Dumb and Dumber *",
"Dune *",
"Dungeons and Dragons *",
"ESPN *",
"Easter Egg Surprise",
"Easter Parade",
"Easter Parade Collection *",
"Eggs in Sports",
"Elf *",
"Elf on the Shelf *",
"Fabulous Decade",
"Fairy Berry Bears",
"Fairy Messengers",
"Fairy Messengers *",
"Family Game Night",
"Family Matters *",
"Family Tree *",
"Fashion Afoot",
"Father Christmas",
"Father Christmas *",
"Favorite Bible Stories",
"Feliz Navidad *",
"Festive Santa",
"Figure Skaters *",
"Fire Brigade",
"Fire Fighters *",
"Fisher-Price *",
"Fishing *",
"Flintstones *",
"Folk Art Americana *",
"Football Jerseys *",
"Football Legends",
"Football Legends *",
"Forest Frolics",
"Forever Friends",
"Fortnite *",
"Friendly Fairies",
"Friends *",
"Friendship Set *",
"Frostlight Faeries *",
"Frosty Friends",
"Frosty Friends *",
"Frosty Fun Decade",
"Frosty Fun Decade *",
"Frosty the Snowman *",
"Funko POP! *",
"G.I. Joe *",
"Game of Thrones *",
"Garden Club",
"Garfield *",
"Ghostbusters *",
"Gift Bearers",
"Gilmore Girls *",
"Glass Bell *",
"Gnome for Christmas",
"Gnome for Christmas *",
"Golf *",
"Gone with the Wind *",
"Granddaughter *",
"Grandson *",
"Greatest Story",
"Gremlins *",
"Hallmark Archives",
"Hallmark Channel *",
"Happiness is Peanuts",
"Happiness is Peanuts *",
"Happy Feet *",
"Happy Halloween!",
"Happy Hatters Annual Collection",
"Happy Holiday Parade Collection *",
"Happy Tappers *",
"Hark, It's Herald",
"Harley-Davidson",
"Harley-Davidson Barbie *",
"Harley-Davidson Motorcycle Milestones",
"Harley-Davidson Motorcycles",
"Harley-Davidson Motorcycles *",
"Harry Potter *",
"Hasbro *",
"Heart of Christmas",
"Heart of Motherhood Collection *",
"Heavenly Angels",
"Heavenly Belles",
"Heirloom Angels",
"Here Comes Easter",
"Here Comes Santa",
"Here Comes Santa *",
"Hershey’s *",
"Hockey Greats",
"Hockey Greats *",
"Hockey Jerseys *",
"Holiday Angels",
"Holiday Barbie",
"Holiday Barbie - Collector’s Club",
"Holiday Flurries",
"Holiday Heirloom",
"Holiday Homecoming Barbie",
"Holiday Lighthouse",
"Holiday Lighthouse *",
"Holiday Parade",
"Holiday Parade *",
"Holiday Traditions",
"Holiday Traditions Barbie *",
"Holiday Wildlife",
"Honda Motorcycles *",
"Hoop Stars",
"Hoops &amp; Yoyo *",
"Hot Wheels *",
"Howliday Helpers",
"I Love Lucy *",
"Ice Block Buddies",
"Indian Motorcycles *",
"Inside Story",
"Inside Story *",
"Invitation to Tea *",
"Itty Bitty Bears *",
"Jack-in-the-Box  Memories",
"Jaws *",
"Joanne Eschrich Artist Collection *",
"John Deere *",
"Jolly Snowmen *",
"Journeys Into Space",
"Joy to the World *",
"Joyful Angels",
"Joyful Santa",
"Joyful Tidings *",
"Jurassic Park *",
"Keepsake Cupcake",
"Keepsake Cupcakes",
"Keepsake Cupcakes *",
"Keepsake Kids *",
"Keepsake Korners *",
"Keepsake Kustoms",
"Kiddie Car Classics",
"Kiddie Car Classics Accessories",
"Kids! Collection *",
"Kittens In Toyland",
"Kringe and Kris",
"Kringleville",
"Kringlewood Farms *",
"Kris and the Kringles",
"Kris' Penguins *",
"L &amp; M Trains *",
"LEGO *",
"Latina Holiday Barbie",
"Legendary Concept Cars",
"Lego Batman *",
"Lego DC Super Heroes *",
"Lego Harry Potter *",
"Lego Star Wars *",
"Light of Bethlehem *",
"Lighthouse Greetings",
"Lil' American Trucks",
"Lil' Classic Cars",
"Lion King *",
"Lionel Blown Glass *",
"Lionel Norfolk and Western",
"Lionel Train",
"Lionel Train *",
"Little Golden Books *",
"Little Tikes *",
"Looney Tunes *",
"Lunch Box *",
"MLB Collection *",
"Madame Alexander",
"Madame Alexander *",
"Madame Alexander Collection *",
"Madame Alexander Dolls *",
"Madame Alexander Holiday Angels",
"Madame Alexander Little Women",
"Majestic Wilderness",
"Making Memories",
"Making Memories *",
"March of the Teddy Bears",
"Marilyn Monroe",
"Marjolein Bastin Collection",
"Marjolein's Garden",
"Marty Links *",
"Mary Engelbreit *",
"Mary Hamilton *",
"Mary Hamilton's Bears",
"Mary's Angels",
"Mary's Angels *",
"Mary's Bears",
"Maxine *",
"Mayor's Christmas Tree *",
"Mean Girls *",
"Memories of Christmas *",
"Merry Makers",
"Merry Music Makers *",
"Merry Olde Santa",
"Merry and Bright *",
"Mickey Express",
"Mickey Express *",
"Mickey Mouse &amp; Friends *",
"Mickey Mouse Clubhouse *",
"Mickey's Christmas Carol",
"Mickey's Christmas Carol *",
"Mickey's Holiday Parade",
"Mickey's Movie Mouseterpieces",
"Minecraft *",
"Miniature Clothespin Soldier",
"Miniature Creche",
"Miniature Fire Brigade",
"Miniature Gumdrop *",
"Miniature Kiddie Car Classics",
"Miniature Kiddie Car Luxury Edition",
"Miniature Rocking Horse",
"Mischievous Kittens",
"Mischievous Kittens *",
"Mistletoe Miss",
"Monopoly *",
"Monopoly Game: Advance to Go",
"Monster Mash Collection *",
"Monsterverse *",
"Morgan Horse *",
"Mother Goose",
"Mr. and Mrs. Claus",
"Muppets *",
"Musical Soldiers",
"My Hero Academia *",
"My Little Pony *",
"Mythical Mermaids",
"NASCAR *",
"NBA Collection *",
"NFL Collection *",
"NHL Collection *",
"National Lampoon's Christmas Vacation *",
"Nativity Lighted Balls *",
"Nature's Angels",
"Nature's Sketchbook",
"Nature's Sketchbook *",
"Nellco Appliances *",
"Nick and Christopher",
"Nickelodeon *",
"Nina’s Reindeer *",
"Nintendo *",
"Noah’s Ark *",
"Noble Nutcrackers",
"Noble Nutcrackers *",
"Noel Nutcrackers",
"Noel R.R.",
"Noelville",
"Noelville *",
"Norman Rockwell",
"Norman Rockwell *",
"North Pole Nutcrackers *",
"North Pole Tree Trimmers",
"North Pole Tree Trimmers *",
"Nostalgic Childhood",
"Nostalgic Houses &amp; Shops",
"Nostalgic Houses &amp; Shops *",
"Nostalgic Houses and Shops *",
"Nurse *",
"Nutcracker Guild",
"Nutcracker Sweet",
"Old English Village",
"On the Road",
"Once Upon a Christmas",
"Once Upon a Christmas *",
"Ornament Tag *",
"Oscar Meyer *",
"Owliver",
"Paddington Bear *",
"Paintbox Pixies",
"Parks and Recreation *",
"Paw Patrol *",
"Peace on Earth",
"Peanuts",
"Peanuts *",
"Peanuts Ball *",
"Peanuts Characters *",
"Peanuts Christmas Pageant *",
"Peanuts Halloween *",
"Peanuts Pageant *",
"Peanuts on Ice *",
"Penguin Friends *",
"Penguin Pal",
"Perfect Harmony *",
"Petite Penguins",
"Pirates of the Caribbean *",
"Pixar Legends",
"Pixie Hollow *",
"Playhouse Disney *",
"Pocahontas  *",
"Porcelain Barbie *",
"Porcelain Bear",
"Porcelain Birds *",
"Potato Heads *",
"Power Rangers *",
"Powered Up with Mario",
"Precious Editions *",
"Precious Moments *",
"Puppy Love",
"Puppy Love *",
"Raggedy Ann &amp; Andy *",
"Reindeer Champs",
"Reindeer Quartet *",
"Rick and Morty *",
"Ring In the Season",
"Ring In the Season *",
"Robert Haas Angels *",
"Robot Parade",
"Rocking Horse",
"Rocking Horse Memories",
"Rocking Horse Memories *",
"Rodney the Reindeer *",
"Romantic Vacations",
"Rudolph the Red Nosed Reindeer *",
"Safe and Snug",
"Sandal the Sandman",
"Sandal the Sandman *",
"Santa &amp; His Reindeer *",
"Santa Certified",
"Santa Claus is Comin' to Town *",
"Santa and Sparky",
"Santa's Holiday Train *",
"Santa's Little Big Top",
"Santa's Midnight Ride *",
"Santa's Sweet Ride",
"Santa's Sweet Ride *",
"Santas from Around the World *",
"Santa’s Big Night *",
"Santa’s Club Soda *",
"Santa’s Sleigh *",
"Sarah Plain and Tall *",
"Scarlett O'Hara",
"Schitt's Creek *",
"Scooby-Doo *",
"Seaside Scenes",
"Season's Treatings",
"Season's Treatings *",
"Seinfeld *",
"Sesame Street *",
"Sharing the Joy *",
"Shrek *",
"Sidewalk Cruisers",
"Simply Beautiful  *",
"Six Mice *",
"Sky's the Limit",
"Sky’s the Limit Miniature",
"Smurfs *",
"Snoopy and Friends",
"Snoopy and Friends *",
"Snoopy and Woodstock *",
"Snow Buddies",
"Snow Buddies *",
"Snow Cozy",
"Snow Cub Club *",
"Snow Many Memories *",
"Snowball and Tuxedo",
"Snowball and Tuxedo *",
"Snowball and Tuxedo - 24th",
"Snowflake Ballet",
"Snowman's Land *",
"Snowmen of Winter Garden *",
"Snowtop Lodge",
"Snowtop Lodge *",
"Sonic the Hedgehog *",
"South Pole Pals *",
"Spin-A-Majigs *",
"Sports Fan *",
"Spotlight on Snoopy",
"Spotlight on Snoopy *",
"Spring is in the Air",
"Springtime Barbie",
"Springtime Bonnets",
"Star Trek *",
"Star Trek Legends",
"Star Trek Ships *",
"Star Wars",
"Star Wars *",
"Star Wars Ships *",
"Stock Car Champions",
"Stocking Stuffers",
"Stocking Stuffers *",
"Stranger Things *",
"Super Heroes *",
"Super Mario *",
"Susan G Komen *",
"Susan G. Komen *",
"Sweet Decade",
"Sweet Decade *",
"Sweet Tooth Treats",
"Tea Time!",
"Team Time!",
"Teddy-Bear Style",
"Teenage Mutant Ninja Turtles *",
"Tender Touches *",
"Terminator *",
"The Avengers *; Super Heroes *",
"The Bearymores",
"The Beauty of Birds",
"The Bellringers",
"The Big Lebowski *",
"The Car's the Star",
"The Clauses on Vacation",
"The Enchanted Memories",
"The Gift Bringers",
"The Golden Girls *",
"The Haunted Mansion Collection *",
"The Hobbit *",
"The Hunger Games *",
"The Jetsons *",
"The Kringles",
"The Language of Flowers",
"The Lord of the Rings *",
"The Matrix *",
"The Merry Bakers *",
"The Nativity",
"The Night Before Christmas",
"The Night Before The Night Before Christmas *",
"The Nightmare Before Christmas *",
"The Nutcracker Ballet",
"The Office *",
"The Old West",
"The Peanuts Collection *",
"The Peanuts Gang",
"The Peanuts Gang *",
"The Polar Express *",
"The Powerpuff Girls *",
"The Simpsons *",
"The Smurfs *",
"The Three Stooges *",
"The Witcher *",
"The Wizard of Oz",
"The Wizard of Oz Collection *",
"The Wonders of Oz",
"The World of Frosty Friends",
"The Year Without a Santa Claus *",
"Thimble",
"Thimble Bells",
"Thomas Kinkade",
"Thomas Kinkade *",
"Thomas the Tank *",
"Tin Locomotive",
"Tin Toys",
"Tis the Seasoning!",
"Tobin Fraley Carousel Horse",
"Tobin Fraley Holiday Carousel",
"Tom and Jerry *",
"Tonka *",
"Town and Country",
"Toy Story *",
"Toymaker Figurine *",
"Toymaker Santa",
"Toymaker Santa *",
"Transformers *",
"Treasures and Dreams",
"Trolls *",
"Turn of the Century Parade",
"Twas the night before Christmas *",
"Tweedle Dee Tweet Collection *",
"Twelve Days of Christmas",
"Twilight Saga *",
"U.S. Christmas Stamps",
"UNICEF *",
"Unforgettable Villains",
"VeggieTales *",
"Victorian Elegance Barbie *",
"Vintage Roadsters",
"Vintage Speedster",
"Voltron *",
"WWE *",
"Walt Disney's Peter Pan *",
"Walt Disney’s The Princess and the Frog",
"Welcome Friends",
"Welcome to Elfville *",
"When Calls the Heart *",
"Windows of Faith",
"Windows of the World",
"Winner’s Circle",
"Winnie the Pooh",
"Winnie the Pooh *",
"Winnie the Pooh Collection *",
"Winnie the Pooh and Christopher Robin, Too",
"Winter Fun With Snoopy",
"Winter Park *",
"Winter Surprise",
"Winter Wonderland",
"Wireless Snowman Band *",
"Wonder and Light *",
"Wooden Pull Toys *",
"Woodland Babies",
"World of Frosty Friends",
"XBOX *",
"Yellowstone *",
"You're An All-Star *",
"Young Santa",
"Young Santa *",
"Yuletide Central",
"Yuletide Harmony Collection *",
"Yuletide Treasures"
]

export default series;