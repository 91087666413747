import React, { useEffect } from 'react';
import styles from './Admin.module.css';
import logo from '../../img/logo.png';
import { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import { useHistory } from "react-router-dom";
import { Row, Col, Container } from 'react-bootstrap';

import { TextField, Select, Button, OutlinedInput, InputLabel, MenuItem, FormControl, Alert } from '@mui/material';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { useTheme } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import Barcode from 'react-barcode';

import artists from '../../constants/artists';
import seriesArr from '../../constants/series';
import categories from '../../constants/categories';
import keywordArr from '../../constants/keywords';
import defaultImage from '../../img/missing.png';

const Admin = ({ firebase }, props) => {

    /* Styles declared */
    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    let history = useHistory();




    /* Declare constants for menus */
    const artistArr = artists.list.map(obj => obj.name);
    const yearArr = Array.from(Array(new Date().getFullYear() - 1972), (_, i) => (i + 1973).toString())


    /* Hooks for value management */

    const startValue = sessionStorage.getItem('itemToEdit');
    const [inputValue, setInputValue] = React.useState(startValue.toString());
    const [imgSrc, setImgSrc] = React.useState(logo);
    const [year, setYear] = React.useState('');
    const [boxnum, setBoxnum] = React.useState('');
    const [imageURL, setImageURL] = React.useState('');
    const [name, setName] = React.useState('');
    const [barcode, setBarcode] = React.useState('');
    const [category, setCategory] = React.useState('');
    const [id, setId] = React.useState('');
    const [series, setSeries] = React.useState('');
    const [seriesnum, setSeriesnum] = React.useState('');
    const [boxPrice, setBoxPrice] = React.useState('');
    const [videoURL, setVideoURL] = React.useState('');

    const [changed, setChanged] = React.useState(false);
    const [changeSummary, setChangeSummary] = React.useState('');

    const [itemState, setItemState] = React.useState(
        {
            barcode: '',
            boxnum: '',
            boxPrice: '',
            category: '',
            imageURL: '',
            keywords: [''],
            name: '',
            series: '',
            seriesnum: '',
            videoURL: '',
            year: '',
            yulelogId: ''
        }
    )
    const [personName, setPersonName] = React.useState([]);
    const [keywords, setKeywords] = React.useState([]);
    const [pgComments, setPgComments] = React.useState('');
    const [proposedChanges, setProposedChanges] = React.useState([]);

    /* Check if user is in fact an Admin. Redirect to landing page if not */
    useEffect(() => {
        firebase.auth.currentUser.getIdTokenResult()
            .then((idTokenResult) => {
                // Confirm the user is an Admin.
                if (!!idTokenResult.claims.adminAccess) {
                    // User is admin
                    getItem(inputValue);
                } else {
                    // Show regular hallmark UI.
                    history.push('/');
                }
            })
            .catch((error) => {
                console.log(error);
                alert(error);
            });
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 400,
            },
        },
    };

    const theme = useTheme();


    const getItem = () => {
        if (!inputValue) {
            return alert("Error: The YuleLog ID field is empty. You must enter a valid ID to load information about an item.");
        }
    
        const fetchDocument = (field, value) => {
            let hallmarkRef = firebase.db.collection("hallmark").where(field, "==", value);
            hallmarkRef.get().then(querySnapshot => {
                if (!querySnapshot.empty) {
                    querySnapshot.forEach(doc => {
                        setImgSrc(doc.data().imageURL);
                        setPersonName(doc.data().artist);
                        setItemState({
                            artist: doc.data().artist,
                            barcode: doc.data().barcode,
                            boxnum: doc.data().boxnum,
                            boxPrice: doc.data().boxPrice,
                            category: doc.data().category,
                            imageURL: doc.data().imageURL,
                            keywords: doc.data().keywords,
                            name: doc.data().name,
                            series: doc.data().series,
                            seriesnum: doc.data().seriesnum,
                            year: doc.data().year,
                            yulelogId: doc.data().yulelogID,
                            videoURL: doc.data().videoURL
                        });
                        setYear(doc.data().year);
                        setId(doc.data().yulelogID)
                        setName(doc.data().name);
                        setBoxnum(doc.data().boxnum);
                        setBarcode(doc.data().barcode);
                        setCategory(doc.data().category);
                        setSeries(doc.data().series);
                        setSeriesnum(doc.data().seriesnum);
                        setImageURL(doc.data().imageURL);
                        setVideoURL(doc.data().videoURL);
                        setBoxPrice(doc.data().boxPrice);
                        setPgComments(parsePgComments(doc.data().pgComments));
                        setKeywords(doc.data().keywords);
                        setChanged(false);
                    });
                } else {
                    console.log("No such document!");
                }
            }).catch(error => {
                console.log("Error getting document:", error);
            });
        };
    
        // First, try to fetch by document ID
        let hallmarkRef = firebase.db.collection("hallmark").doc(inputValue);
        hallmarkRef.get().then(doc => {
            if (doc.exists) {
                setImgSrc(doc.data().imageURL);
                setPersonName(doc.data().artist);
                setItemState({
                    artist: doc.data().artist,
                    barcode: doc.data().barcode,
                    boxnum: doc.data().boxnum,
                    boxPrice: doc.data().boxPrice,
                    category: doc.data().category,
                    imageURL: doc.data().imageURL,
                    keywords: doc.data().keywords,
                    name: doc.data().name,
                    series: doc.data().series,
                    seriesnum: doc.data().seriesnum,
                    yulelogId: doc.data().yulelogID,
                    year: doc.data().year,
                    videoURL: doc.data().videoURL
                });
                setYear(doc.data().year);
                setId(doc.data().yulelogID)
                setName(doc.data().name);
                setBoxnum(doc.data().boxnum);
                setBarcode(doc.data().barcode);
                setCategory(doc.data().category);
                setSeries(doc.data().series);
                setSeriesnum(doc.data().seriesnum);
                setImageURL(doc.data().imageURL);
                setVideoURL(doc.data().videoURL);
                setBoxPrice(doc.data().boxPrice);
                setPgComments(parsePgComments(doc.data().pgComments));
                setKeywords(doc.data().keywords);
                setChanged(false);
            } else {
                // If no document found, try to fetch by box number
                fetchDocument("boxnum", inputValue);
            }
        }).catch(error => {
            console.log("Error getting document:", error);
        });
    };



    const findChangedValues = (object1, object2) => {
        const changedValues = {};
        for (const key in object1) {
            if (object2.hasOwnProperty(key) && JSON.stringify(object1[key]) !== JSON.stringify(object2[key])) {
                changedValues[key] = object2[key];
            }
        }
        return changedValues;
    }

    const submitItem = () => {
        let timeStamp = Math.round(+new Date() / 1000);

        let itemObj = {
            year: parseInt(year),
            boxnum: boxnum,
            barcode: barcode,
            category: category,
            name: name,
            series: series,
            seriesnum: parseInt(seriesnum),
            boxPrice: parseFloat(boxPrice),
            artist: personName,
            keywords: keywords,
            pgComments: reverseParsePgComments(pgComments),
            timestamp: timeStamp,
            videoURL: videoURL,
            yulelogID: id
        }

        let oldObj = itemState;
        console.log("OLD", itemState)
        console.log("NEW", itemObj)

        let diffObj = findChangedValues(oldObj, itemObj);
        diffObj["yulelogID"] = id;
        diffObj["timeStamp"] = timeStamp;
        console.log("DIFF", "test")

        setChangeSummary(JSON.stringify(diffObj, null, 4));

        let key = inputValue + '_' + timeStamp;

        alert(key);

        let editRef = firebase.db.collection("admin").doc("edits").collection("hallmark").doc(key);

        editRef.set({
            diffObj
        }).then(() => {
            alert("Document successfully written!");
            setChanged(false)
            getItem(inputValue);
        })
            .catch((error) => {
                console.error("Error writing document: ", error);
                alert("Error writing document: ", error);
            });

        return console.log(itemObj);
    }
    /* eslint-disable no-useless-escape */
    const parsePgComments = (text) => {
        return text.replace(/<br\s*[\/]?>/gi, "\n");
    }
    /* eslint-disable no-useless-escape */

    const reverseParsePgComments = (text) => {
        return text.replace(/\n/g, "<br>");
    }

    const handleInputChange = (event) => {
        setInputValue(event.target.value)
    }

    const flagAsChanged = () => {
        if (inputValue) {
            setChanged(true);
            console.log(year)
        }
    }


    /* HANDLE CHANGE METHODS */

    const handleArtistChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        flagAsChanged();
    };

    const handleKeywordChange = (event) => {
        const {
            target: { value },
        } = event;
        let keywordsArr = typeof value === 'string' ? value.split(',') : value;
        console.log("keywords", keywordsArr)
        const filteredArr = keywordsArr.filter((element) => element !== "");
        setKeywords(
            // On autofill we get a the stringified value.
            filteredArr
        );
        flagAsChanged();
    };

    const handleYearChange = (event) => {
        setYear(event.target.value)
        flagAsChanged();
    };

    const handleVideoURLChange = (event) => {
        setVideoURL(event.target.value)
        flagAsChanged();
    };

    const handleImageURLChange = (event) => {
        setImageURL(event.target.value)
        flagAsChanged();
    };


    const handleNameChange = (event) => {
        setName(event.target.value);
        flagAsChanged();
    };

    const handleBoxnumChange = (event) => {
        setBoxnum(event.target.value);
        flagAsChanged();
    };

    const handleBarcodeChange = (event) => {
        setBarcode(event.target.value);
        flagAsChanged();
    };

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
        flagAsChanged();
    }

    const handleSeriesChange = (event) => {
        setSeries(event.target.value)
        flagAsChanged()
    }

    const handleSeriesnumChange = (event) => {
        setSeriesnum(event.target.value)
        flagAsChanged();
    }

    const handleBoxPriceChange = (event) => {
        setBoxPrice(event.target.value)
        flagAsChanged();
    }

    const handlePgCommentsChange = (event) => {
        setPgComments(event.target.value)
        flagAsChanged();
    }


    return (
        <div>
            <div className={styles.Header}>
                <h1 className={styles.Headline}>YuleLog Price Guide Curation Tool</h1>
            </div>
            <div className={styles.EditorWrapper}>
                <strong>YuleLog ID or Boxnum:&nbsp;&nbsp;</strong>
                <input
                    autoFocus
                    className={styles.InputId}
                    id="input"
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange} />&nbsp;&nbsp;
                <Button variant="outlined" onClick={getItem}>Load Item</Button>
                &nbsp; &nbsp; &nbsp; &nbsp;{id}
                <br></br><br></br>
                <div className={styles.DetailsWrapper}>
                    <div className={styles.Image}>
                        <img src={imgSrc || defaultImage} alt="Item img" width="360px" height="360px" id="image" />
                    </div>
                    <div className={styles.Details}>
                        <Container>
                            <Row>
                                <Col xs={2}>
                                    <FormControl>
                                        <InputLabel id="year">Year</InputLabel>
                                        <Select sx={{ minWidth: 100 }}
                                            labelId="year-label"
                                            id="year-select"
                                            value={year}
                                            onChange={handleYearChange}
                                            input={<OutlinedInput label="Year" />}
                                        >
                                            {yearArr.map((year) => (
                                                <MenuItem
                                                    key={year}
                                                    value={year}
                                                    style={getStyles(year, personName, theme)}
                                                >
                                                    {year}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col xs={3}>
                                    <TextField fullWidth id="boxnum" label="Box #" variant="outlined" value={boxnum} onChange={handleBoxnumChange} />
                                </Col>
                                <Col xs={3}>
                                    <TextField
                                        fullWidth
                                        id="barcode"
                                        label="Barcode"
                                        type="number"
                                        variant="outlined"
                                        value={barcode}
                                        onChange={handleBarcodeChange}
                                    />
                                </Col>
                                <Col xs={4}>
                                    <Barcode value={barcode} format="upc" width={2} height={50} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormControl sx={{ width: 250 }}>
                                        <InputLabel id="category">Category</InputLabel>
                                        <Select sx={{ minWidth: 100 }}
                                            labelId="category-label"
                                            id="category-select"
                                            value={category}
                                            onChange={handleCategoryChange}
                                            input={<OutlinedInput label="Category" />}
                                        >
                                            {categories.map((category) => (
                                                <MenuItem
                                                    key={category}
                                                    value={category}
                                                    style={getStyles(category, personName, theme)}
                                                >
                                                    {category}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row>
                                <Col sx={{ width: 800 }} className="mt-3">
                                    <TextField fullWidth id="name" label="Name" variant="outlined" onChange={handleNameChange} value={name} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormControl sx={{ width: 850 }} className="mt-3">
                                        <InputLabel id="demo-multiple-artist-label">Artist</InputLabel>
                                        <Select
                                            labelId="demo-multiple-artist-label"
                                            id="demo-multiple-artist"
                                            multiple
                                            value={personName}
                                            onChange={handleArtistChange}
                                            input={<OutlinedInput label="Name" />}
                                            MenuProps={MenuProps}
                                        >
                                            {artistArr.map((name) => (
                                                <MenuItem
                                                    key={name}
                                                    value={name}
                                                    style={getStyles(name, personName, theme)}
                                                >
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col>
                                    <FormControl sx={{ width: 450, marginTop: '10px!important' }}>
                                        <InputLabel id="series">Series</InputLabel>
                                        <Select sx={{ minWidth: 100 }}
                                            labelId="series-label"
                                            id="series-select"
                                            value={series}
                                            onChange={handleSeriesChange}
                                            input={<OutlinedInput label="Series" />}
                                        >
                                            {seriesArr.map((series) => (
                                                <MenuItem
                                                    key={series}
                                                    value={series}
                                                    style={getStyles(series, personName, theme)}
                                                >
                                                    {series}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col>
                                    <TextField
                                        type="number"
                                        helperText="0 - default"
                                        label="Series #"
                                        value={seriesnum}
                                        onChange={handleSeriesnumChange}
                                        sx={{ width: '10ch', textAlign: 'center', marginTop: '10px!important' }}
                                        InputProps={{
                                            inputProps: {
                                                max: 99, min: 1
                                            }
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <FormControl fullWidth sx={{ m: 7, marginTop: '10px!important' }}>
                                        <CurrencyTextField
                                            id='boxPrice'
                                            label={"Box Price"}
                                            variant="outlined"
                                            textAlign="left"
                                            onBlur={(event, value) => handleBoxPriceChange(event, value)}
                                            value={boxPrice}
                                            minimumValue='0'
                                            maximumValue='100000'
                                            currencySymbol="$"
                                            outputFormat="number"
                                            placeholder="0.00"
                                        />
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <TextField
                                    multiline
                                    rows={7}
                                    label={"Price Guide Comments"}
                                    placeholder={"Price Guide Comments"}
                                    value={pgComments}
                                    onChange={handlePgCommentsChange}
                                    className={styles.Comments}
                                    defaultValue={pgComments}
                                    InputLabelProps={{ shrink: true }} />
                            </Row>
                            <Row className="mt-3">
                                <FormControl sx={{ width: 950 }} className="mt-1">
                                    <InputLabel id="demo-multiple-keyword-label">Keywords</InputLabel>
                                    <Select
                                        labelId="demo-multiple-keyword-label"
                                        id="demo-multiple-keyword"
                                        multiple
                                        value={keywords}
                                        onChange={handleKeywordChange}
                                        input={<OutlinedInput label="Keywords" />}
                                        MenuProps={MenuProps}
                                    >
                                        {keywordArr.map((keyword) => (
                                            <MenuItem
                                                key={keyword}
                                                value={keyword}
                                                style={getStyles(keyword, personName, theme)}
                                            >
                                                {keyword}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Row>
                            <Row className="mt-3">
                                <TextField
                                    className={styles.Comments}
                                    type="text"
                                    helperText="Image URL hosted on Firebase"
                                    label={"Image URL"}
                                    value={imageURL}
                                    onChange={handleImageURLChange}
                                />
                            </Row>
                            <Row className="mt-3">
                                <TextField
                                    className={styles.Comments}
                                    type="text"
                                    helperText="YouTube video URL - hosted on the YuleLog channel"
                                    label={"Video URL"}
                                    value={videoURL}
                                    onChange={handleVideoURLChange}
                                />
                            </Row>
                        </Container>
                        <span style={{ userSelect: 'all' }}>{itemState.name} - {itemState.boxnum}</span>
                        <p style={{ userSelect: 'all' }}>Add this item to to your collection with the YuleLog Price Guide and Database:<br></br>
                            https://yulelog.com/priceguide?ornament={inputValue}
                        </p>
                    </div>
                </div>
                <div className={styles.Button}>
                    <Button onClick={submitItem} disabled={!changed} size="large" variant="contained" endIcon={<SendIcon />}>
                        Submit Changes For Review
                    </Button>
                </div>
                <div className={styles.ChangeTextAreaWrapper}>
                    <textarea readOnly value={changeSummary} placeholder="Change Data (read-only)" className={styles.ChangeTextArea} />
                </div>
            </div>

        </div >
    )
}

const condition = authUser => !!authUser;

export default withFirebase(withAuthorization(condition)(Admin));