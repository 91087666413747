import React, { useEffect, forwardRef, useState, useMemo, Fragment } from 'react';
import { useLocation } from 'react-router-dom'

/* This is Financial Report. There are a lot of moving parts here. */

/* Firebase */
import { AuthUserContext, withAuthorization } from '../../../components/Session';
import Firebase, { withFirebase } from '../../../components/Firebase';

/* MUI Components */
import { AppBar, Toolbar, CssBaseline, Divider, Drawer, Typography, List, Link, Snackbar, CircularProgress } from '@mui/material';

/* MUI Icons */
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

/* YuleLog components */
import SmallLogo from '../../../components/Logos/SmallLogo/SmallLogo';
import ButtonRow from '../UI/ButtonRow/ButtonRow';
import TableNavigationRow from '../UI/TableNavigationRow/TableNavigationRow';
import DrawerList from '../../../components/Drawers/DrawerList';
import ReportDrawerList from '../../../components/Reports/ReportDrawerList/ReportDrawerList';
import CollectionModal from '../../../components/UI/Modal/CollectionModal/CollectionModal';

/* Supporting libraries */
import algoliasearch from 'algoliasearch';
import NumberFormat from 'react-number-format';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import localforage from 'localforage';
import moment from 'moment';

/* CSS */
import styles from './CustomReport.module.css';
import reportStyles from '../Reports.module.css';
import { useStyles } from '../../../constants/useStyles';

/* Lib constants and methods */
import { COLUMN_HEADERS, REPORT_TEXT } from '../../../constants/properties';
import { getPriceChange } from '../../../lib/reportSummary';
import { getRandomLoaderText } from '../../../lib/getRandomLoaderText';

/* react-table methods and plugins */
import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce, useGroupBy, useExpanded } from 'react-table'
import { useExportData } from "react-table-plugins";
import { matchSorter } from 'match-sorter';

const CustomReport = ({ firebase }) => {


    /* First things first, update the page title */
    document.title = 'YuleLog Custom Report';

    /*** CONSTANTS ***/


    /* State Hooks */
    const [itemData, setItemData] = React.useState(0);
    const [collectionData, setCollectionData] = React.useState(0);
    const [modalShow, setModalShow] = React.useState(false);
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [snackbarText, setSnackbarText] = React.useState("Item saved successfully");
    const showD56 = localStorage.getItem('showD56');
    const [tableData, setResource] = React.useState([]);
    const reportType = localStorage.getItem('reportType');

    let showStatus = false
    let filterObj = {}

    if (reportType === 'wishlist') {
        showStatus = true
        filterObj = { id: 'status', value: 'Wanted' }
    }

    useEffect(() => {
        getHits().then(
            setTimeout(() => setLoading(false), 5000)
        )
        async function getHits() {
            var result = await localforage.getItem('hits'); //wait for the localforage item
            setResource(JSON.parse(result) || [])
        }
    }, [])

    /* Set Table Data to hits from localStorage. This should match the active user's collection data. */

    const skipPageResetRef = React.useRef()

    /* Constants for the user and user's index */
    let user = firebase.auth.currentUser.uid;
    const ALGOLIA_ID = 'G89C4NZVH8';
    const ALGOLIA_ADMIN_KEY = localStorage.getItem("search");
    const client = algoliasearch(ALGOLIA_ID, ALGOLIA_ADMIN_KEY);

    /*** HELPER METHODS ***/

    /* Control Snackbar opening */
    const openSnackbar = (text) => {
        setSnackbarText(text);
        setOpen(true);
    };

    /* Control Snackbar closing */
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    /* Controls what happens when item in table is clicked. Opens CollectionModal. */
    const handleItemDetails = (yulelogID, objectID, brand) => (event) => {
        setItemData(0);
        setCollectionData(0);
        let collectionData;
        yulelogID = yulelogID.toString();
        let itemBrand = (brand === "Hallmark") ? "hallmark" : "dept56";
        // or get the single doc from the collection
        firebase.db.collection(itemBrand).doc(yulelogID)
            .get()
            .then(doc => {
                let data = { ...doc.data(), objectID: objectID };
                setItemData(data);
            });
        firebase.db.collection("users").doc(user).collection("maincollection").doc(objectID)
            .get()
            .then(doc => {
                collectionData = { ...doc.data(), objectID: objectID };
            }).then(() => {
                setCollectionData(collectionData);
                setModalShow(true)
            })
    }

    /* Controls what happens when user closes the CollectionHit modal */
    const handleItemDetailsClose = () => {
        setModalShow(false);
        setItemData(0);
    }

    /* Called when user edits an item and saves it. Refreshes table data. */
    const updateTableData = (objectID, updateObj) => {
        let tableObj = tableData.find(item => item.objectID === objectID);
        let objIndex = tableData.findIndex(item => item.objectID === objectID);
        let updatedObj = { ...tableObj, ...updateObj };
        updatedObj['objectID'] = objectID
        let finalArr = [...tableData];
        finalArr[objIndex] = updatedObj;
        skipPageResetRef.current = true
        setResource(finalArr)
    }

    /* Helps faciliate row being updated in table */
    React.useEffect(() => {
        // After the table has updated, always remove the flag
        skipPageResetRef.current = false
    })

    /* Method to handle saving an item being edited in the Modal. Should match what happens in Collection view */
    const handleSaveItem = (objectID, user, item) => (event) => {

        let updateObj = {
            dateBought: item.dateBought,
            dateSold: item.dateSold,
            myComments: item.myComments,
            priceBought: item.priceBought,
            priceSold: item.priceSold,
            condition: item.condition,
            quantity: item.quantity,
            status: item.status,
            signed: item.signed,
            purchaseLocation: item.purchaseLocation,
            displayLocation: item.displayLocation,
            storageLocation: item.storageLocation,
            collector: item.collector,
            collection: item.collection
        }

        /* Update Firebase */
        firebase.db.collection("users").doc(user).collection("maincollection").doc(objectID)
            .update(updateObj).then(function () {
                const index = client.initIndex("userItems");
                index.partialUpdateObject({
                    ...updateObj,
                    objectID: objectID
                }).then(() => {
                    /* Update the table with the new data if the Firebase write succeeded */
                    updateTableData(objectID, updateObj);
                    handleItemDetailsClose();
                    openSnackbar("Item succesfully updated.");
                    setRefresh(true);
                });
            });

        /* Check if any of the user-specific arrays (location + collector/collection) were updated */
        let arrObj = {};
        if (item.purchaseLocationArray) {
            arrObj['purchaseLocation'] = item.purchaseLocationArray;
            localStorage.setItem("purchaseLocations", JSON.stringify(item.purchaseLocationArray));
        }
        if (item.displayLocationArray) {
            arrObj['displayLocation'] = item.displayLocationArray;
            localStorage.setItem("displayLocations", JSON.stringify(item.displayLocationArray));
        }
        if (item.storageLocationArray) {
            arrObj['storageLocation'] = item.storageLocationArray;
            localStorage.setItem("storageLocations", JSON.stringify(item.storageLocationArray));
        }
        if (item.collectorArray) {
            arrObj['collector'] = item.collectorArray;
            localStorage.setItem("collectors", JSON.stringify(item.collectorArray));
        }
        if (item.collectionArray) {
            arrObj['collection'] = item.collectionArray;
            localStorage.setItem("collections", JSON.stringify(item.collectionArray));
        }

        /* Only update Firestore locations array if changes were detected and object is populated */
        if (item.updatedArray && Object.entries(arrObj).length > 0) {
            firebase.db.collection("users").doc(user).collection("userinfo").doc("locations").update({
                arrObj
            })
                .then(function () {

                }).catch(function (error) {
                    // The document probably doesn't exist.
                    console.error("Error updating document: ", error);
                });
        }
    }

    /* If item is deleted, delete from Firebase and update table data */
    const handleDeleteItem = (objectID, yulelogID, user) => (event) => {
        firebase.db.collection("users").doc(user).collection("maincollection").doc(objectID)
            .delete()
            .then(doc => {
                var filtered = tableData.filter((el) => el.objectID != objectID);
                setResource(filtered);
                const index = client.initIndex("userItems");
                index.deleteObject(objectID).then(() => {
                    let collectionArr = JSON.parse(localStorage.getItem("collectionItems"));
                    let index = collectionArr.indexOf(yulelogID);
                    if (index > -1) {
                        collectionArr.splice(index, 1);
                    }
                    localStorage.setItem("collectionItems", JSON.stringify(collectionArr));
                    setRefresh(true);
                    openSnackbar("Item deleted from collection.");
                    handleItemDetailsClose();
                });
            });
    }

    /* Package and export data + columns for Print view */
    function getExportFileBlob({ columns, data, fileType, fileName }) {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const headerNames = columns.map((column) => column.exportValue);
        var totalValue = data.reduce((sum, el) => sum + el[headerNames.indexOf('Value')], 0);
        var totalQty = data.reduce((sum, el) => sum + el[headerNames.indexOf('Qty')], 0);
        var totalPriceBought = data.reduce((sum, el) => sum + el[headerNames.indexOf('Price Bought')], 0);
        var totalPriceSold = data.reduce((sum, el) => sum + el[headerNames.indexOf('Price Sold')], 0);
        var totalProfit = data.reduce((sum, el) => sum + el[headerNames.indexOf('Profit')], 0);

        var data = [...data,]

        let rows = data.map(function (item) {
            return Object.values(item);
        });

        let totalsRow = generateTotalsRow(headerNames, totalValue, totalQty, totalPriceBought, totalPriceSold, totalProfit);

        let printData = {
            headers: headerNames,
            rows: rows,
            totals: totalsRow
        };

        /* Set print data in local storage */
        try {
            localforage.setItem('printData', printData);
        }
        catch (e) {
            return alert("Your collection is too large to print. Please export it as a CSV.")
        }

        localStorage.setItem('reportType', 'YuleLog Custom Report');

        // TODO: Some additional configuration; might not be necessary
        /* var docDefinition = {
            pageOrientation: 'landscape',
            content: [
                {
                    layout: 'lightHorizontalLines', // optional
                    table: {`
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 1,

                        info: {
                            title: 'YuleLog Financial Report',
                            author: 'YuleLog Software',
                        },
                        body: [
                            headerNames,
                            ...rows,
                            totalsRow
                        ]
                    }
                }
            ]
        
        };
        pdfMake.createPdf(docDefinition).download();
        */
    }

    /* Method to generate the totals row for the print version of report */
    const generateTotalsRow = (columns, totalValue, totalQty, totalPriceBought, totalPriceSold, totalProfit) => {
        /* Find out how many columns there are */
        const len = columns.length
        /* Create array to store totals values */
        const totals = Array(len).fill("");
        /* Constants to determine indices of columns that are totaled */
        const qtyCol = columns.indexOf('Qty');
        const priceBoughtCol = columns.indexOf('Price Bought');
        const priceSoldCol = columns.indexOf('Price Sold');
        const valueCol = columns.indexOf('Value');
        const profitCol = columns.indexOf('Profit');

        /* Populate columns for totals row with values */
        totals[0] = 'Totals';
        totals[qtyCol] = totalQty;
        totals[priceBoughtCol] = new Intl.NumberFormat('us-US', { style: 'currency', currency: 'USD' }).format(totalPriceBought);
        totals[priceSoldCol] = new Intl.NumberFormat('us-US', { style: 'currency', currency: 'USD' }).format(totalPriceSold);
        totals[profitCol] = new Intl.NumberFormat('us-US', { style: 'currency', currency: 'USD' }).format(totalProfit);
        totals[valueCol] = new Intl.NumberFormat('us-US', { style: 'currency', currency: 'USD' }).format(totalValue);

        return totals;
    }

    const getDate = (date) => {
        if (date === 0 || undefined || null) {
            return '';
        }
        const formattedDate = moment.unix(date).format("MM/DD/YYYY");
        return formattedDate;
    }

    /* Method to calculate value for secondary market estimate */
    const getValue = (condition, mibPrice, qty, signed) => {
        let value = 0;
        let qtynum = Number(qty);
        switch (condition) {
            case "Never Removed From Box":
                value = (mibPrice * 1.05) * qtynum;
                break;
            case "Mint In Box":
                value = mibPrice * qtynum;
                break;
            case "No Price Tag":
                value = (mibPrice * .95) * qtynum;
                break;
            case "Slightly Damaged Box":
                value = (mibPrice * .90) * qtynum;
                break;
            case "Damaged Box":
                value = (mibPrice * .85) * qtynum;
                break;
            case "No Box":
                value = (mibPrice * .80) * qtynum;
                break;
            default:
                value = mibPrice * qtynum;
        }
        if (signed) {
            value = value + (0.25 * mibPrice);
        }

        return Number((Math.round(value * 100) / 100).toFixed(2));
    }

    /* Grab the raw collection data and map it for a table-friendly version */
    let collection = tableData.map(a => {
        console.log(a)
        return (
            {
                imageURL: a.imageURL,
                brand: a.brand,
                year: a.year ? a.year.toString() : "",
                name: a.name,
                boxnum: a.boxnum,
                boxPrice: a.boxPrice,
                artist: a.artist.join(", "),
                quantity: a.quantity,
                series: a.series,
                seriesnum: a.seriesnum,
                myComments: a.myComments,
                pgComments: a.pgComments,
                condition: a.condition,
                signed: a.signed ? "Signed" : "",
                priceBought: a.priceBought,
                priceSold: a.priceSold,
                dateBought: getDate(a.dateBought),
                dateSold: getDate(a.dateSold),
                profit: (a.priceSold - a.priceBought),
                mibPrice: a.mibPrice,
                value: getValue(a.condition, a.mibPrice, a.quantity, a.signed),
                priceChange: getPriceChange(a.mibPrice, a.boxPrice),
                yulelogID: a.yulelogID,
                objectID: a.objectID,
                brand: a.brand,
                status: a.status,
                displayLocation: a.displayLocation,
                purchaseLocation: a.purchaseLocation,
                storageLocation: a.storageLocation,
                collector: a.collector,
                collection: a.collection
            }
        )
    });

    /* Set data to the mapped version of tableData, called "collection" now */
    const data = React.useMemo(
        () => collection,
        [tableData]
    )

    /* Checkbox for use in the "Show Columns" box */
    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }, ref) => {
            const defaultRef = React.useRef()
            const resolvedRef = ref || defaultRef

            React.useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate
            }, [resolvedRef, indeterminate])

            return <input type="checkbox" ref={resolvedRef} {...rest} />
        }
    )

    /* Define a default UI for filtering */
    function DefaultColumnFilter({
        column: { filterValue, preFilteredRows, setFilter },
    }) {
        const count = preFilteredRows.length

        return (
            <input
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                }}
                placeholder={`Search ${count} records...`}
            />
        )
    }

    /* Set up the search feature */
    function GlobalFilter({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
    }) {
        const count = preGlobalFilteredRows.length
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 500)

        return (
            <span>
                <b>{REPORT_TEXT.search}</b>{' '}
                <input
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    style={{
                        fontSize: '1.1rem',
                        border: '1',
                    }}
                />
            </span>
        )
    }

    /* This is a custom filter UI for selecting a unique option from a list */
    function SelectColumnFilter({
        column: { filterValue, setFilter, preFilteredRows, id },
    }) {
        /* Calculate the options for filtering using the preFilteredRows */
        const options = React.useMemo(() => {
            const options = new Set()
            preFilteredRows.forEach(row => {
                options.add(row.values[id])
            })
            return [...options.values()]
        }, [id, preFilteredRows])

        /* Render a multi-select box */
        return (
            <select
                value={filterValue}
                onChange={e => {
                    setFilter(e.target.value || undefined)
                }}
            >
                <option value="">All</option>
                {options.sort().map((option, i) => (
                    <option key={i} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        )
    }

    /* Declare columns used in the table and customize them when necessary */
    const columns = React.useMemo(
        () => [
            {
                Header: 'Image',
                accessor: 'imageURL',
                show: false,
                Filter: <br></br>,
                Cell: ({ cell: { value } }) => (
                    <img
                        src={value}
                        width={60}
                    />
                )
            },
            {
                Header: 'Brand',
                accessor: 'brand',
                show: false,
                Filter: SelectColumnFilter,
                filter: 'includes'
            },
            {
                Header: 'Year',
                accessor: 'year', // accessor is the "key" in the data
                Filter: SelectColumnFilter,
                filter: 'includes',
                Cell: ({ cell: { value } }) => {
                    return (
                        <span style={{ cursor: 'pointer' }}>
                            {value === "0" ? "" : value}
                        </span>
                    )
                }
            },
            {
                Header: 'Name',
                accessor: 'name',
                maxWidth: 300,
                minWidth: 300,
                width: 300,
                Filter: <br></br>,
                Cell: ({ value, row }) => (<Link style={{ textDecoration: 'none' }} className={styles.HitLink} onClick={(e) => handleItemDetails(row.original.yulelogID, row.original.objectID, row.original.brand)(e)}>{value}</Link>)
            },
            {
                Header: 'Box #',
                accessor: 'boxnum',
                Filter: <br></br>,
            },
            {
                Header: 'Artist',
                show: false,
                maxWidth: 200,
                minWidth: 200,
                width: 200,
                accessor: 'artist',
                Filter: <br></br>
            },
            {
                Header: 'Qty',
                accessor: 'quantity',
                show: !showStatus,
                Filter: <br></br>,
                Footer: info => {
                    // Only calculate total visits if rows change
                    let total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => row.values.quantity + sum, 0),
                        [info.rows]
                    )

                    return <b>{total}</b>
                }
            },
            {
                Header: 'Series',
                accessor: 'series',
                show: false,
                maxWidth: 200,
                minWidth: 200,
                width: 200,
                Filter: SelectColumnFilter,
                filter: 'includes',
                aggregate: 'count',
                Aggregated: ({ value }) => `${value} items`,
            },
            {
                Header: 'Series #',
                show: false,
                accessor: 'seriesnum',
                Filter: <br></br>,
                Cell: ({ cell: { value } }) => (
                    value === 0 ? "" : value
                )
            },
            {
                Header: 'My Comments',
                accessor: 'myComments',
                show: false,
                maxWidth: 400,
                minWidth: 400,
                width: 400,
                Filter: <br></br>
            },
            {
                Header: 'Price Guide Comments',
                accessor: 'pgComments',
                show: false,
                maxWidth: 400,
                minWidth: 400,
                width: 400,
                Filter: <br></br>,
                Cell: ({ cell: { value } }) => (
                    value ? (<div dangerouslySetInnerHTML={{ __html: value }}></div>) : null
                )
            },
            {
                Header: 'Condition',
                accessor: 'condition',
                show: !showStatus,
                maxWidth: '150px',
                Filter: SelectColumnFilter,
                filter: 'includes',
                aggregate: 'count',
                Aggregated: ({ value }) => `${value} items`
            },
            {
                Header: 'Signed',
                accessor: 'signed',
                Filter: SelectColumnFilter,
                filter: 'includes',
                show: false,
                maxWidth: '50px',
            },
            {
                Header: 'Status',
                accessor: 'status',
                show: showStatus,
                Filter: SelectColumnFilter,
                filter: 'includes',
                aggregate: 'count',
                Aggregated: ({ value }) => `${value} items`
            },
            {
                Header: 'Price Bought',
                accessor: 'priceBought',
                Filter: <br></br>,
                show: !showStatus,
                Footer: info => {
                    // Only calculate total visits if rows change
                    let total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => row.values.priceBought + sum, 0),
                        [info.rows]
                    )

                    return <b><NumberFormat
                        value={total}
                        fixedDecimalScale
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'} /></b>
                }
            },
            {
                Header: 'Price Sold',
                accessor: 'priceSold',
                show: !showStatus,
                Filter: <br></br>,
                Footer: info => {
                    // Only calculate total visits if rows change
                    let total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => row.values.priceSold + sum, 0),
                        [info.rows]
                    )

                    return <b><NumberFormat
                        value={total}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'} /></b>
                }
            },
            {
                Header: 'Profit',
                accessor: 'profit',
                show: false,
                Filter: <br></br>,
                Footer: info => {
                    // Only calculate total visits if rows change
                    let total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => row.values.profit + sum, 0),
                        [info.rows]
                    )

                    return <b><NumberFormat
                        value={total}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'} /></b>
                }
            },
            {
                Header: 'Date Bought',
                accessor: 'dateBought',
                show: false,
                Filter: <br></br>
            },
            {
                Header: 'Date Sold',
                accessor: 'dateSold',
                show: false,
                Filter: <br></br>
            },
            {
                Header: 'Box Price',
                accessor: 'boxPrice',
                Filter: <br></br>
            },
            {
                Header: 'MIB Price',
                accessor: 'mibPrice',
                Filter: <br></br>
            },
            {
                Header: 'Price Change',
                accessor: 'priceChange',
                show: false,
                Filter: <br></br>
            },
            {
                Header: 'Value',
                show: !showStatus,
                accessor: 'value',
                Filter: <br></br>,
                Footer: info => {
                    // Only calculate total visits if rows change
                    let total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => row.values.value + sum, 0),
                        [info.rows]
                    )

                    return <b><NumberFormat
                        value={total}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'} /></b>
                }
            },
            {
                Header: 'Collector',
                accessor: 'collector',
                show: false,
                Filter: SelectColumnFilter,
                filter: 'equals',
                aggregate: 'count',
                Aggregated: ({ value }) => `${value} collectors`
            },
            {
                Header: 'Collection',
                accessor: 'collection',
                show: false,
                Filter: SelectColumnFilter,
                filter: 'equals',
                aggregate: 'count',
                Aggregated: ({ value }) => `${value} collections`
            },
            {
                Header: 'Display Location',
                accessor: 'displayLocation',
                show: false,
                maxWidth: 200,
                minWidth: 200,
                width: 200,
                Filter: SelectColumnFilter,
                filter: 'equals',
                aggregate: 'count',
                Aggregated: ({ value }) => `${value} locations`
            },
            {
                Header: 'Purchase Location',
                accessor: 'purchaseLocation',
                show: false,
                maxWidth: 200,
                minWidth: 200,
                width: 200,
                Filter: SelectColumnFilter,
                filter: 'equals',
                aggregate: 'count',
                Aggregated: ({ value }) => `${value} locations`
            },
            {
                Header: 'Storage Location',
                accessor: 'storageLocation',
                show: false,
                maxWidth: 200,
                minWidth: 200,
                width: 200,
                Filter: SelectColumnFilter,
                filter: 'equals',
                aggregate: 'count',
                Aggregated: ({ value }) => `${value} locations`
            }
        ],
        []
    )

    /* Set which filters are default */
    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    /* Used for global search */
    function fuzzyTextFilterFn(rows, id, filterValue) {
        return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
    }

    /* Let the table remove the filter if the string is empty */
    fuzzyTextFilterFn.autoRemove = val => !val

    /* Set up filter types */
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    /* Set up the table proper; declare what features/plugins are used */
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        setGroupBy,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, groupBy, expanded },
        prepareRow,
        allColumns,
        getToggleHideAllColumnsProps,
        state,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        exportData
    } = useTable({
        columns,
        data,
        autoResetPage: !skipPageResetRef.current,
        autoResetExpanded: !skipPageResetRef.current,
        autoResetGroupBy: !skipPageResetRef.current,
        autoResetSelectedRows: !skipPageResetRef.current,
        autoResetSortBy: !skipPageResetRef.current,
        autoResetFilters: !skipPageResetRef.current,
        autoResetRowState: !skipPageResetRef.current,
        defaultColumn,
        getExportFileBlob,
        filterTypes,
        initialState: {
            hiddenColumns: columns.map(column => {
                if (column.show === false) return column.accessor || column.id;
            }),
            filters: [
                filterObj,
            ],
            pageSize: 50,
            pageIndex: 0
        },
    },
        useFilters,
        useGlobalFilter,
        useGroupBy,
        useSortBy,
        useExportData,
        useExpanded,
        usePagination
    )

    /* Set styles for table */
    const classes = useStyles();

    /* Leaf columns are used for grouping/aggregation */
    const getLeafColumns = function (rootColumns) {
        return rootColumns.reduce((leafColumns, column) => {
            if (column.columns) {
                return [...leafColumns, ...getLeafColumns(column.columns)];
            } else {
                return [...leafColumns, column];
            }
        }, []);
    }

    /* Finally, render the component */
    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <div className={classes.root}>
                    <CssBaseline />
                    <AppBar position="fixed" className={classes.appBar}>
                        <Toolbar>
                            <SmallLogo className={styles.Logo} />
                            <div>
                                <Typography variant="h6" noWrap >
                                    {REPORT_TEXT.reports}
                                </Typography>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        className={classes.drawer}
                        variant="permanent"
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <Toolbar />
                        <DrawerList />
                        <div className={classes.drawerContainer}>
                            <List />
                            <Divider />
                            <ReportDrawerList />
                        </div>
                    </Drawer>
                    <main className={classes.content}>
                        <Toolbar />
                        {loading &&
                            <div>
                                <center>
                                    <h6 className={styles.LoaderText} id="changeText">
                                        <Typography
                                            color="primary"
                                            gutterBottom
                                            variant="body1">
                                            <b>{getRandomLoaderText()}</b>
                                        </Typography>
                                    </h6>
                                </center>
                                <div className={styles.LoaderWrapper}>
                                    <CircularProgress color="primary" />
                                </div>
                            </div>}
                        {!loading && <div>
                            <div className={styles.Row}>
                                <div className={styles.Left}>
                                    <ButtonRow
                                        clicked={() => {
                                            exportData();
                                        }}
                                        data={data}
                                        reportTitle={"YuleLog Custom Report." +
                                            new Date().toLocaleDateString('en-US', {
                                                month: '2-digit',
                                                day: '2-digit',
                                                year: 'numeric',
                                            })}>
                                        <div className={reportStyles.Checkboxes}>
                                            <div>
                                                <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />{REPORT_TEXT.showAllColumns}
                                            </div>
                                            <hr></hr>
                                            {allColumns.map(column => (
                                                <div key={column.id}>
                                                    <label>
                                                        <input id={column.id} type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                                                        {COLUMN_HEADERS[column.id]}
                                                    </label>
                                                </div>
                                            ))}
                                            <br />
                                        </div>
                                    </ButtonRow>
                                    <div className={styles.GroupByWrapper}>
                                        <span className={styles.GroupByLabel}>{REPORT_TEXT.groupBy}</span>
                                        <select
                                            value={groupBy[0]}
                                            className={styles.GroupBySelect}
                                            onChange={e => {
                                                setGroupBy([e.target.value]);
                                            }}
                                        >
                                            <option value="">{REPORT_TEXT.none}</option>
                                            {getLeafColumns([
                                                <option key={'brand'} value={'brand'}>{COLUMN_HEADERS.brand}</option>,
                                                <option key={'series'} value={'series'}>{COLUMN_HEADERS.series}</option>,
                                                <option key={'condition'} value={'condition'}>{COLUMN_HEADERS.condition}</option>,
                                                <option key={'status'} value={'status'}>{COLUMN_HEADERS.status}</option>,
                                                <option key={'collector'} value={'collector'}>{COLUMN_HEADERS.collector}</option>,
                                                <option key={'collection'} value={'collection'}>{COLUMN_HEADERS.collection}</option>,
                                                <option key={'displayLocation'} value={'displayLocation'}>{COLUMN_HEADERS.displayLocation}</option>,
                                                <option key={'purchaseLocation'} value={'purchaseLocation'}>{COLUMN_HEADERS.purchaseLocation}</option>,
                                                <option key={'storageLocation'} value={'storageLocation'}>{COLUMN_HEADERS.storageLocation}</option>
                                            ])}
                                        </select>
                                    </div>
                                </div>
                                <div className={styles.Right}>
                                    <GlobalFilter
                                        preGlobalFilteredRows={preGlobalFilteredRows}
                                        globalFilter={state.globalFilter}
                                        setGlobalFilter={setGlobalFilter}
                                    />
                                </div>
                            </div>
                            {/* Beginning of table */}
                            <table className={styles.Table} {...getTableProps()}>
                                <thead
                                    style={{
                                        border: '1px solid black',
                                        borderRadius: '10px',
                                        backgroundColor: 'lightgray'
                                    }}>
                                    {headerGroups.map(headerGroup => (
                                        headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps()}
                                                style={{
                                                    marginBottom: '20px',
                                                    title: 'Click to sort',
                                                    cursor: 'pointer',
                                                    color: 'black',
                                                    fontWeight: 'bold',
                                                    minWidth: column.minWidth,
                                                    width: column.width
                                                }}>

                                                <div>
                                                    <span {...column.getSortByToggleProps({ title: "Click to sort" })} style={{ padding: '5px' }}>
                                                        {column.render('Header')}
                                                        {/* Add a sort direction indicator */}
                                                        <span>
                                                            {column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? '▼'
                                                                    : '▲'
                                                                : ''}
                                                        </span>
                                                    </span>
                                                </div>
                                                {/* Render the columns filter UI */}
                                                <div>{column.canFilter ? column.render('Filter') : null}</div>
                                            </th>
                                        ))
                                    ))
                                    }
                                </thead>
                                <tbody {...getTableBodyProps()}
                                    style={{
                                        border: '1px solid black',
                                    }}>
                                    {page.map((row, i) => {
                                        prepareRow(row)
                                        return (
                                            <tr style={{
                                                padding: '5px'
                                            }}{...row.cells.map(cell => {
                                                return (
                                                    <td
                                                        // For educational purposes, let's color the
                                                        // cell depending on what type it is given
                                                        // from the useGroupBy hook
                                                        {...cell.getCellProps()}
                                                        style={{
                                                            background: cell.isGrouped
                                                                ? '#0aff0082'
                                                                : cell.isAggregated
                                                                    ? '#ffa50078'
                                                                    : cell.isPlaceholder
                                                                        ? '#ff000042'
                                                                        : 'white',
                                                        }}
                                                    >
                                                        {cell.isGrouped ? (
                                                            // If it's a grouped cell, add an expander and row count
                                                            <>
                                                                <span {...row.getToggleRowExpandedProps()}>
                                                                    {/* TODO: CHANGE FINGER TO ARROW */}
                                                                    {row.isExpanded ? '▼' : '👉'}
                                                                </span>{' '}
                                                                {cell.render('Cell')} ({row.subRows.length})
                                                            </>
                                                        ) : cell.isAggregated ? (
                                                            // If the cell is aggregated, use the Aggregated
                                                            // renderer for cell
                                                            cell.render('Aggregated')
                                                        ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                                                            // Otherwise, just render the regular cell
                                                            cell.render('Cell')
                                                        )}
                                                    </td>
                                                )
                                            })}
                                                {...row.getRowProps()}>
                                                {row.cells.map(cell => {
                                                    return (
                                                        <td
                                                            // For educational purposes, let's color the
                                                            // cell depending on what type it is given
                                                            // from the useGroupBy hook
                                                            {...cell.getCellProps()}
                                                            style={{
                                                                background: cell.isGrouped
                                                                    ? '#0aff0082'
                                                                    : cell.isAggregated
                                                                        ? '#ffa50078'
                                                                        : cell.isPlaceholder
                                                                            ? '#ff000042'
                                                                            : 'white',
                                                            }}
                                                        >
                                                            {cell.isGrouped ? (
                                                                // If it's a grouped cell, add an expander and row count
                                                                <>
                                                                    <span {...row.getToggleRowExpandedProps()}>
                                                                        {row.isExpanded ? '▼' : '▶'}
                                                                    </span>{' '}
                                                                    {cell.render('Cell')} ({row.subRows.length})
                                                                </>
                                                            ) : cell.isAggregated ? (
                                                                // If the cell is aggregated, use the Aggregated
                                                                // renderer for cell
                                                                cell.render('Aggregated')
                                                            ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                                                                // Otherwise, just render the regular cell
                                                                cell.render('Cell')
                                                            )}
                                                        </td>
                                                    )
                                                })}

                                            </tr>
                                        )
                                    })}
                                </tbody>
                                <tfoot style={{
                                    padding: '20px',
                                    border: '1px solid black',
                                    marginBottom: '10px'
                                }}>
                                    {footerGroups.map(group => (
                                        <tr
                                            style={{
                                                backgroundColor: 'lightgray',
                                                borderTop: 'solid 1px',
                                                color: 'black',
                                                fontWeight: 'bold',
                                            }} {...group.getFooterGroupProps()}>
                                            {group.headers.map(column => (
                                                <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tfoot>
                            </table>
                            <br></br>
                            <TableNavigationRow
                                nextClicked={() => nextPage()}
                                nextDisabled={!canNextPage}
                                previousClicked={() => previousPage()}
                                previousDisabled={!canPreviousPage}
                                onPageSizeChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}
                                pageSize={pageSize}
                                pageIndex={pageIndex}
                                pageOptions={pageOptions.length}
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    gotoPage(page)
                                }} />
                            <div>
                            </div>

                        </div>}
                        {(collectionData && itemData) ?
                            < CollectionModal
                                name={itemData.name}
                                customName={collectionData.name}
                                customYear={collectionData.year}
                                artist={itemData.artist}
                                boxnum={itemData.boxnum}
                                customBoxnum={collectionData.boxnum}
                                year={itemData.year}
                                category={itemData.category}
                                village={itemData.village}
                                customVillage={collectionData.village}
                                brand={collectionData.brand}
                                series={itemData.series}
                                seriesnum={itemData.seriesnum}
                                customSeries={collectionData.series}
                                imageurl={collectionData.customPhoto ? collectionData.imageURL : itemData.imageURL}
                                mibPrice={itemData.prices ? itemData.prices.price2024 : 0}
                                boxPrice={itemData.boxPrice}
                                price2017={itemData.price2017}
                                price2018={itemData.price2018}
                                price2019={itemData.price2019}
                                price2020={itemData.price2020}
                                pgComments={itemData.pgComments}
                                pgImg={itemData.imageURL}
                                objectID={itemData.objectID}
                                yulelogID={itemData.yulelogID}
                                keywords={itemData.keywords}
                                collector={collectionData.collector}
                                collection={collectionData.collection}
                                condition={collectionData.condition}
                                customImg={collectionData.imageURL}
                                showCustomImage={collectionData.customPhoto}
                                dateBought={collectionData.dateBought}
                                dateSold={collectionData.dateSold}
                                displayLocation={collectionData.displayLocation}
                                myComments={collectionData.myComments}
                                quantity={collectionData.quantity}
                                priceBought={collectionData.priceBought}
                                priceSold={collectionData.priceSold}
                                purchaseLocation={collectionData.purchaseLocation}
                                signed={collectionData.signed}
                                status={collectionData.status}
                                storageLocation={collectionData.storageLocation}
                                user={user}
                                show={modalShow}
                                animation={false}
                                hide={handleItemDetailsClose}
                                onExited={handleItemDetailsClose}
                                deleteItem={handleDeleteItem}
                                saveItem={handleSaveItem}
                            />
                            : null
                        }
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            open={open}
                            autoHideDuration={5000}
                            onClose={handleSnackbarClose}
                            message={snackbarText}
                            action={
                                <React.Fragment>
                                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </React.Fragment>
                            }
                        />
                    </main>
                </div>
            )
            }
        </AuthUserContext.Consumer >
    )
}


const condition = authUser => !!authUser;

export default withFirebase(withAuthorization(condition)(CustomReport));