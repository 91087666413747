import React from 'react'
import { makeStyles } from '@mui/styles';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import styles from './ButtonRow.module.css';
import Button from '@mui/material/Button';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import TableViewIcon from '@mui/icons-material/TableView';
import { ExportToCsv } from 'export-to-csv';
import { fabClasses } from '@mui/material';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../../constants/routes';


export default function ButtonRow(props) {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;



    const generateCSV = () => {
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            filename: props.reportTitle,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };

        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(props.data);
    }

    return (
        <div className={styles.ButtonRow}>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {props.children}
            </Popover>
            <div className={styles.Row}>
                <div className={styles.Right}>

                </div>
                <div className={styles.Left}>
                    {/* <Button className={styles.LeftButton} variant="outlined" onClick={props.clicked} startIcon={<PictureAsPdfIcon />}>
                        Export as PDF
                    </Button> */}
                    &nbsp;
                    <Button variant="outlined" onClick={generateCSV} startIcon={<TableViewIcon />}>
                        Export as CSV
                    </Button>
                    &nbsp;
                    <Button aria-describedby={id} variant="contained" onClick={handleClick} variant="outlined" startIcon={<VisibilityIcon />}>
                        Show/Hide Columns
                    </Button>
                    &nbsp;
                    <Link
                        style={{ textDecoration: 'none' }}
                        target="_blank"
                        to={ROUTES.PRINT_REPORT}>
                        <Button aria-describedby={id} onClick={props.clicked} variant="contained" variant="outlined" startIcon={<PrintIcon />}>
                            Print
                        </Button>
                    </Link>
                </div>
            </div>
        </div >
    )
}
